import styled, { css } from 'styled-components';
import { Theme } from '@material-ui/core/styles';

import { blackColor, drawerWidth, main, whiteColor } from 'components/theme/constants';
import shadows from 'components/theme/shadows';
import images from 'components/theme/images';
import { Container } from 'components/core';

const raise = 60;

export const layoutWrap = ({ theme }) => css` 
  ${theme.breakpoints.up('sm')} {
    margin-left: ${drawerWidth * 0.8}px;
  }
`
export const layoutWrapFixed = ({ theme }) => css`
  width: 100%;
  ${theme.breakpoints.up('sm')} {
    left: ${drawerWidth * 0.8}px;
    width: auto;
  }
`

export const ContainerStyled = styled(Container)`
  margin: 0;
  margin-top: ${raise}px;
  margin-bottom: 5px;
  position: relative;
  z-index: 3;
`;
export const ContainerCardStyled = styled(ContainerStyled)`
  background: ${main.background};
  box-shadow: ${shadows.shadow16dp};
  padding: 8px;
  border-radius: 6px;
  margin-bottom: 1rem;
`;

export const ContainerPlainStyled = styled(ContainerStyled)`
`;

export const ContArrow = styled.div`
  position: absolute;
  right: calc(50% - 5px);
  top: calc(50% - 5px);
  border-top: 1px solid #bebebe;
  border-right: 1px solid #bebebe;
  transform: rotate(45deg);
  height: 10px;
  width: 10px;
`
export const ContRight = styled.div`
  position: relative;
  width: 50%;
  padding: 0 0 0 0.5rem;
`
export const ContLeft = styled(ContRight)`
  padding: 0 0.5rem 0 0;
  &:before {
    top: 0px;
  }
  &:after {
    bottom: 0px;
  }
  &:after,&:before {
    content: '';
    position: absolute;
    height: calc(50% - 7px);
    width: 1px;
    background: #bebebe;
    right: 0px;
  }  
`

export const LeftRightWrapper = styled.div`
  display: flex;
  position: relative;
`
export const MD = styled.div`
  & ul {
    padding-left: 1rem;
  }
`

export const ArticleStyled = styled.article`
  padding: 0 5px;
  
  background-color: ${whiteColor};
  opacity: 0.7;
  ${layoutWrap}
`
export const MainWrapper = styled.main<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  min-height: 100vh;

  padding: 0px 5px ${({ theme }) => theme.mixins.toolbar.minHeight}px 5px;
  margin-top: -${raise - 5}px;
  margin-bottom: 35px;

  ${layoutWrap}
`;