import React, { FC } from 'react';
import moment from 'moment';

import { Logo } from 'components/logo/Logo';

import {
  Box,
  FooterButtonEmailStyled,
  FooterButtonFacebookStyled,
  FooterButtonTwitterStyled,
  Text
} from './styled';

import { Container, Grid, TableContainer } from 'components/core';
import { CustomLink } from 'components/button/CustomLink';
import { routes } from 'setup/constants';


export const Footer: FC = () => {
  const year = moment().year()
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Box justifyContent="center">
          <CustomLink out={routes.footerEmail}>
            <FooterButtonEmailStyled />
          </CustomLink>
          <CustomLink out={routes.footerTwitter}>
            <FooterButtonTwitterStyled />
          </CustomLink>
          <CustomLink out={routes.footerFacebook}>
            <FooterButtonFacebookStyled />
          </CustomLink>
        </Box>
      </Grid>        
      <Grid item xs={12} md={6}>
        <Box justifyContent="flex-start">
          <Logo />
          <Text weight="small">
            Copyright © 2017-{year} MaxMix Soft
          </Text>
        </Box>
      </Grid>
      <p>&nbsp;</p>
    </Grid>
)
}