import styled, { css } from 'styled-components';
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Avatar, IconButton, Tab, Tabs, Container } from 'components/core';
import { AppBarStyled } from 'components/styled';
import { blackColor, drawerWidth, grayColor, whiteColor } from 'components/theme/constants';
import shadows from 'components/theme/shadows';
import { layoutWrap } from 'components/layout/styled';


export const HeaderDialogWrapper = styled.div`
  display: flex;
  border: 0;
  padding: 0;
  color: ${grayColor[1]};
  background: transparent;
  width: 100%;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: flex-start;
  position: relative;
  z-index: 4;

  .MuiToolbar-root {
    position: relative;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 3rem;
  }
`

export const HeaderDialogWrapperDark = styled(HeaderDialogWrapper)`
  color: ${whiteColor};

  &:after {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 3rem;
    content: '';
    display: block;
    background: ${blackColor};
    opacity: .8;
    left: 0;
  }
`
export const HeaderTabsWrapperStyled = styled.div`
  margin-bottom: -6px;
  background: ${whiteColor};
`
export const HeaderTabsStyled = styled(Tabs).attrs(props => ({
  indicatorColor: 'secondary'
}))`
  & a {
    text-decoration: none;
  }
  .MuiTabs-indicator {
    top: 0;
    bottom: auto;
  }
`
export const HeaderTabStyled = styled(Tab)`
  font-size: 0.7rem;
  text-transform: none;
  color: ${grayColor[18]};
  ${({ selected }) => selected ? '' : css`
    background: ${grayColor[17]};
  `}
`

export const HeaderAvatarIcon = styled(Avatar)`
  color: black;
  background-color: transparent;
`
export const IconButtonStyled = styled(IconButton)`
  font-size: 0.65rem;
`

export const NavRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${({ right }) => right && css`
    margin-right: -1rem;
  `}
`;
export const NavLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const ToolbarStyled = styled.div<{ theme: Theme }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${blackColor};
  ${({ theme }) => css`
    min-height: ${theme.mixins.toolbar.minHeight}px;
  `}
`;

export const HeaderAppBarStyled = styled(AppBarStyled)`
  background: transparent;
  box-shadow: none;
  .MuiContainer-root {
    margin: 0;
  }
  display: flex;
  flex-grow: 1;
  ${layoutWrap}
`
export const HeaderContainerStyled = styled(Container).attrs({
  maxWidth: 'lg'
})`
  ${({ theme }) => css`
    width: calc(100% - ${drawerWidth * 0.8}px);
    ${theme.breakpoints.down('xs')} {
      width: 100%;
    }
  `}
`
export const HeroContainerStyled = styled(HeaderContainerStyled).attrs({
})`
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: 0;
    }
  `}
  .MuiGrid-container {
    justify-content: center;
  }
`