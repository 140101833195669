import { Header } from 'components/header/Header'
import { NavLeft, NavRight } from 'components/header/styled'
import { Logo } from 'components/logo/Logo'
import React, { FC } from 'react'
import useI18n from 'use/i18n/useI18n'
import { HeaderMenu } from './HeaderMenu'



export const PublicHeaderCompose: FC = ({
  children
}) => {
  const { t } = useI18n()
  const brand = t('common.brand')

  return (
    <Header hero={children}>
      <NavLeft>
        <Logo />
        { brand.toUpperCase() }
      </NavLeft>
      <NavRight>
        <HeaderMenu />
      </NavRight>
    </Header>
  )
}