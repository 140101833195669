import React, { FC, memo } from 'react';

import {
  LinkLogo,
  LogoLeftStyled,
  LogoWrapper,
} from './styled';
import images from 'components/theme/images';
import { Button } from 'components/core';

interface IProps {
  className?: string;
}
export const LogoMemo: FC<IProps> = ({
  className
}) => {
  return (
    <LinkLogo to="/" className={className}>
      <Button color="inherit">
        <LogoWrapper>
          <LogoLeftStyled>
            <img src={images.logo} />
          </LogoLeftStyled>
        </LogoWrapper>
      </Button>
    </LinkLogo>
  );
};

export const Logo = memo(LogoMemo);