import styled, { css } from 'styled-components'
import { BottomNavigation } from '@material-ui/core'
import { Avatar, Toolbar } from 'components/core'
import { blackColor, grayColor, hexToRgb, whiteColor } from 'components/theme/constants'
import shadows from 'components/theme/shadows'
import { colorBackground, gradientBackground, outlinedBackground, whiteBackground } from 'components/common/styled'
import { Button } from 'components/button/custom/styled'


const black = hexToRgb(blackColor)
const white = hexToRgb(whiteColor)

const AvatarRaised = styled(Avatar)`
  position: absolute;
  margin-top: -2.5rem;
  left: calc(50% - 1rem);
`
export const AvatarColor = styled(AvatarRaised)`
  ${props => colorBackground(props)};
`
export const AvatarWhite = styled(AvatarRaised)`
  ${props => whiteBackground(props)};
`
export const BottomNavigationStyled = styled(BottomNavigation)`
  justify-content: flex-end;
  height: 3rem;
  padding: 0 1rem 0.5rem;
  background: transparent;
`
const CustomCard = styled.div`
  border: 1px solid ${grayColor[6]};
  border-radius: 6px;

  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;

  font-size: .875rem;
  color: rgba(${black}, 0.87);

  background: ${whiteColor};
  width: 100%;
  box-shadow: ${({ raised }) => raised ? shadows.cardRaised : shadows.card};

  ${({ background }) => background ? css`
    background-position: 50%;
    background-size: cover;
    text-align: center;
    color: ${whiteColor};
    & p {
      color: rgba(${white}, 0.7);
    }
    &:after {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: block;
      left: 0;
      top: 0;
      content: '';
      background-color: rgba(${black}, 0.56);
      border-radius: 6px;
    }
    & small {
      color: rgba(${white}, 0.7);
    }
  ` : ''}
`
const CustomActionsCard = styled(CustomCard)`
`
const CustomRaisedCard = styled(CustomCard)`
  border: 0;
  margin-bottom: 2rem;
  margin-top: 5rem;
`
export const CustomActionsCardWhite = styled(CustomActionsCard)`
  ${props => whiteBackground(props)};
`
export const CustomActionsCardColor = styled(CustomActionsCard)`
  ${props => gradientBackground(props)};
`
export const CustomRaisedCardWhite = styled(CustomRaisedCard)`
  ${props => whiteBackground(props)};
`
export const CustomRaisedCardColor = styled(CustomRaisedCard)`
  ${props => gradientBackground(props)};
`

export const CustomCardBody = styled.div`
  padding: ${({ overflow }) => overflow ? '1rem 0 1rem 1rem' : '1rem'};
  flex: 1 1 auto;
  ${({ background }) => background ? css`
    position: relative;
    z-index: 2;
    min-height: 280px;
    padding-top: 40px;
    padding-bottom: 40px;
    max-width: 440px;
    margin: 0 auto;
  ` : ''}
  ${({ color }) => color ? css`
    border-radius: 6px;
    & h1,& h2,& h3 {
      & small {
        color: rgba(${white}, 0.8);
      }
    }
    `: ''}
`
const CustomToolbarCardHeader = styled(Toolbar)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: auto;
  padding: 0.25rem;
`
const CustomRaisedCardHeader = styled.div`
  border-radius: 8px;
  padding: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: -2rem;
  border: 0;
  margin-bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
`
export const CustomCardWhiteHeaderRaised = styled(CustomRaisedCardHeader)`
  ${props => whiteBackground(props)};
`
export const CustomCardColorHeaderRaised = styled(CustomRaisedCardHeader)`
  ${props => gradientBackground(props)};
`
export const CustomCardWhiteHeaderToolbar = styled(CustomToolbarCardHeader)`
  ${props => whiteBackground(props)};
`
export const CustomCardColorHeaderToolbar = styled(CustomToolbarCardHeader)`
  ${props => gradientBackground(props)};
`
export const UserDataButton = styled(Button).attrs(props => ({
  variant: props.variant || 'contained'
}))`
  float: right;
  padding: 0.5rem 1rem;

  ${(props) => props.variant === 'outlined'
    && outlinedBackground(props)
  }
`