import styled, { css } from 'styled-components';
import { Theme } from '@material-ui/core/styles';

import { TextDefault } from 'components/typography';
import { ButtonStyled } from 'components/card/styled';
import icons from 'components/theme/icons';
import { blackColor } from 'components/theme/constants';

interface IBox {
  theme: Theme;
  justifyContent: string;
}
export const Box = styled.div<IBox>`
  height: 100%;
  display: flex;
  align-items: center;

  margin-top: 1rem;
  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints.values.md}px) {
    justify-content: center;
  }
`

export const FooterButtonStyled = styled(ButtonStyled).attrs({
  justIcon: true,
  round: true,
})`
`
export const FooterButtonEmailStyled = styled(FooterButtonStyled).attrs({
  icon: icons.email,
  color: 'secondary'
})`
`
export const FooterButtonFacebookStyled = styled(FooterButtonStyled).attrs({
  icon: icons.facebook,
  color: 'facebook'
})`
`
export const FooterButtonTwitterStyled = styled(FooterButtonStyled).attrs({
  icon: icons.twitter,
  color: 'twitter'
})`
`
export const FooterButtonWhatsAppStyled = styled(FooterButtonStyled).attrs({
  icon: icons.whatsapp,
  color: 'whatsapp'
})`
`
export const FooterWrapper = styled.div.attrs({
  maxWidth: 'lg'
})`
  padding-bottom: 4rem;
`
export const Text = styled(TextDefault)`
  color: ${blackColor};
`

