import styled, { css } from 'styled-components';
import { grayColor, whiteColor } from 'components/theme/constants';
import { RawButtonWithIcon } from '../RawButtonWithIcon';
import { buttonBackground } from 'components/common/styled';


export const Button = styled(RawButtonWithIcon)`
  min-height: auto;
  min-width: auto;
  background-color: ${grayColor[0]};
  color: ${whiteColor};
  
  border: none;
  border-radius: 3px;
  position: relative;
  padding: 0.5rem 1rem;
  margin: .3125rem 1px;
  font-size: 0.8rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0;
  will-change: box-shadow, transform;
  transition:
  box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  outline: none;

  ${({ round }) => round ? css`
    border-radius: 30px;
  ` : ''}

  ${({ justIcon }) => justIcon ? css`
    padding: 0.5rem 0.5rem 0.5rem 0.7rem;
  ` : ''}

  ${props => buttonBackground(props)}

  ${({ disabled }) => disabled ? css`
      background-color: ${grayColor[6]};
    ` : ''}

  & svg {
    position: relative;
    display: inline-block;
    top: 0;
    width: 18px;
    height: 18px;
    margin-right: 4px;
    vertical-align: middle;
  }

  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  &:disabled {
    cursor: not-allowed;
  }
`;