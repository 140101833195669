import styled, { css } from 'styled-components';

import { Theme } from '@material-ui/core/styles';

import { blackColor, grayColor, hexToRgb, infoColor, successColor, whiteColor } from 'components/theme/constants';
import shadows from 'components/theme/shadows';
import { Toolbar } from 'components/core';
import { Button } from 'components/button/custom/styled';
import { CustomActionsCardColor } from 'components/card/custom/styled';

interface IAvatarProps {
  theme: Theme;
  shadow: boolean;
}

function getToolbarColor(color) {
  return {
    success: `linear-gradient(60deg, #66bb6a, #43a047)`,
    info: `linear-gradient(60deg,#26c6da,#0097a7)`
  }[color] || grayColor[0]
}

export const AvatarStyled = styled.div<IAvatarProps>`
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
  box-shadow: ${({ theme, shadow }) => shadow ? theme.shadows[4] : 'none'};
  & img {
    width: 100%;
    height: auto;
  }
`
export const ButtonStyled = styled(Button).attrs(props => ({
  round: true,
  justIcon: true,
  color: props.color || 'rose'
}))`
  margin: 0 0 0 0.5rem;
  height: 2rem;
  width: 2rem;
`
export const CardBlockStyled = styled.div`
  border-radius: 5px;
  background: ${whiteColor};
  box-shadow: ${shadows.innerShadow};
`
export const CardContentStyled = styled.div`
  width: 100%;
`
export const CardHeader = styled.div`
  color: ${whiteColor};
  font-size: 0.7rem;
  padding: 0.5rem .5rem;
  margin: 0;
  background: ${blackColor};
  border-radius: 4px 4px 0 0;
  position: relative;
`
export const CardPopupStyled = styled(CustomActionsCardColor)`
  margin: 1rem;
  width: calc(100% - 2rem);
  min-width: 16rem;
  ${({ noPadding }) => css`
    padding: ${noPadding ? '0' : '1rem'};
  `
}
`
export const CardToolbarStyled = styled(Toolbar).attrs(props => ({
  color: props.color
}))`
  padding: 0 0.5rem;
  margin: 0;
  width: 100%;
  color: ${whiteColor};

  border-radius: 3px;
  position: relative;

  background: ${({ color }) => getToolbarColor(color)};
  box-shadow: ${shadows.drawer};
`
export const CardToolbarWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
`
export const FooterStyled = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0.9375rem 1.875rem;
  padding-top: 0;
  text-align: center;
`
export const FooterOverlayStyled = styled(FooterStyled)`
  background: rgba(0,0,0,0.8);
  margin-left: 5%;
  position: relative;
  box-shadow: ${({ theme }) => theme.shadows[16]};
  padding: 0.2rem;
  border-radius: 0 0 10px 10px;
  width: 90%;
  overflow: hidden;
`


export const InfoWrapperStyled = styled.div`
  display: flex;
`
export const DateStyled = styled.div`
  color: ${infoColor[0]};
  font-style: italic;
  font-weight: bold;
`
export const ChatStyled = styled.div`
  color: ${grayColor[1]};
  font-style: italic;
`
export const ActionWrapper = styled(CardHeader)`
  min-width: 0;
  border-radius: 5px;
  box-shadow: ${shadows.innerShadow};
  width: 100%;
  padding: 0.2rem;
  background: linear-gradient(60deg, rgb(244, 245, 247), #b5d7fa);
`
export const CardCollectionSkeleton = styled.div`
  --bg-color: ${grayColor[2]};
  --title-height: ${({ titleHeight }) => titleHeight !== undefined ? titleHeight : 3.5}rem;
  --title-pos: 0.5rem 5rem;
  --title-size: calc(100% - 1rem) var(--title-height);
  height: 9rem;
  background-repeat: no-repeat;
  background-image: 
    /* animation blur */
    linear-gradient(90deg, rgba(238,238,238,.5) 0%, rgba(190,190,190,.5) 100%),
    /* title */
    linear-gradient(white var(--title-height), transparent 0),
    /* gray rectangle that covers whole element */
    linear-gradient(var(--bg-color) 100%, transparent 0);

  background-size:
    3rem 100%,  /* animation */
    var(--title-size), /* title */
    100% 100%;  /* card bg */

  background-position:
    0% 0,    /* animation */
    var(--title-pos), /* title */
    0 0;        /* card bg */
  animation: loading 2.5s infinite;
  @keyframes loading {
    from {
      background-position:
        -150% 0,    /* animation */
        var(--title-pos), /* title */
        0 0;        /* card bg */
    }
    to {
      background-position:
        300% 0,        
        var(--title-pos), /* title */
        0 0;        /* card bg */
    }
  }
`