import React, { FC } from 'react'

import {
  HeaderAppBarStyled,
  HeaderContainerStyled,
  HeroContainerStyled,
  ToolbarStyled,
} from './styled'
import { IChildren } from 'utils/interface'

export interface IHeaderProps extends IChildren {
  hero?: React.ReactNode;
}
export interface IHeaderType {
  value: string;
  linkTo: string;
  size?: string;
  className?: string;
}

export const Header: FC<IHeaderProps> = ({
  children,
  hero
}) => {

  return (
    <HeaderAppBarStyled position="relative">
      <HeaderContainerStyled>
        <ToolbarStyled>{children}</ToolbarStyled>
      </HeaderContainerStyled>
      { hero && <HeroContainerStyled>{ hero }</HeroContainerStyled>}
    </HeaderAppBarStyled>
  )
}
