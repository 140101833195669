import { drawers } from 'setup/constants'
import React, { FC } from 'react'
import useMenu from 'use/menu/useMenu'
import { MenuIcon } from 'components/common/common.styled'

export const HeaderMenu: FC = ({
}) => {
  const { toggle } = useMenu(drawers.menu, '')

  return (
    <MenuIcon onClick={toggle} />
  )
}