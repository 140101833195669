import React, { FC, memo } from 'react'

import { IChildrenOpt } from 'utils/interface'
import { Footer } from 'components/footer/Footer'

import {
  ContainerCardStyled,
  ContainerPlainStyled,
  MainWrapper,
} from './styled'

export enum ILayout {
  PLAIN = 'P',
  CARD = 'C',
}
interface IProps extends IChildrenOpt {
  variant?: ILayout;
}

export const Layout: FC<IProps> = ({ 
  variant = ILayout.CARD,
  children
}) => {
  const Container = {
    [ILayout.PLAIN]: ContainerPlainStyled,
    [ILayout.CARD]: ContainerCardStyled
  }[variant]

  return (
    <MainWrapper>
      <Container maxWidth="lg">
        {children}
        
      </Container>
      <Footer />
    </MainWrapper>
  )
}

export const MLayout = memo(Layout)